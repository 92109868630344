import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ItemPagerComponent } from './item-pager/item-pager.component';
import { ItemPagerItemComponent } from './item-pager-item/item-pager-item.component';
import { InlineItemPagerComponent } from './inline-item-pager/inline-item-pager.component';
import { InlineItemPagerItemComponent } from './inline-item-pager-item/inline-item-pager-item.component';

@NgModule({
  declarations: [ItemPagerComponent, ItemPagerItemComponent, InlineItemPagerComponent, InlineItemPagerItemComponent],
  imports: [CommonModule, RouterModule],
  exports: [ItemPagerComponent, InlineItemPagerComponent],
})
export class ItemPagerModule {}
